import React from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/layout"
import {SharpImage} from "../utils/types"
import {GatsbyImage} from "gatsby-plugin-image"

interface Props {
  readonly data: PageQueryData
}

const Pricing: React.FC<Props> = ({data}) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title="Tercen" keywords={[`tercen`, `data science platform`]}>
      <header className="w-full bg-tercen-blue relative">
        <GatsbyImage
          image={data.heroBackground.childImageSharp.gatsbyImageData}
          alt="pricing background image"
          style={{maxHeight: "340px", minHeight: "160px"}}
        />
        <section className="absolute inset-0">
          <div className="container h-full justify-center flex flex-col">
            <h1 className="text-white font-bold text-3xl md:text-4xl lg:text-5xl px-4">Bring value to your data.</h1>
            <h2 className="text-tercen-yellow text-lg md:text-xl font-source-sans-pro font-semibold pt-3 px-4">
              Empower the researcher, liberate the bioinformatician.
            </h2>
          </div>
        </section>
      </header>
      <section className="w-full py-16 px-3 md:px-36">
        <div className="container">
          <h2 className="text-4xl font-extrabold text-black text-center">Pricing plans</h2>
          <h3 className="text-lg py-6 text-center font-source-sans-pro">The perfect plan for any size organisation.</h3>
        </div>
        <div className="container pt-6 flex flex-col xl:flex-row px-2 md:px-4">
          <div className="flex-1 m-4 shadow-xl flex flex-col">
            <div className="bg-tercen-green-base py-1 mb-8 "></div>
            <div className="p-4 pb-24 flex-grow">
              <h4 className="py-6 text-3xl font-bold text-black text-center">Free</h4>
              <ul className="px-8 text-sm pricing-list font-source-sans-pro">
                <li>Tercen Cloud</li>
                <li>Full Tercen Feature Set (Including Projects, Teams, Public and Private sharing)</li>
                <li>10 GB of storage</li>
                <li>10 hours of CPU/month</li>
              </ul>
            </div>
            <div className="bg-gray-100 text-center py-6">
              <p className="text-5xl font-black text-black">
                0<span className="text-xl font-light font-source-sans-pro uppercase">€/month</span>
              </p>
              <p className="text-sm font-light">Ex. VAT</p>
            </div>
            <a
              href={data.site.siteMetadata.signUpLink}
              target="_blank"
              rel="noreferrer"
              className="flex font-source-sans-pro text-xl font-bold tracking-wide justify-center bg-tercen-green-base transition duration-500 ease-in-out hover:bg-tercen-green-hover text-white py-3 px-10">
              Sign Up Free
            </a>
          </div>
          <div className="flex-1 m-4 shadow-xl flex flex-col">
            <div className="bg-tercen-blue py-1 text-center text-2xl font-source-sans-pro text-white">Most Popular</div>
            <div className="p-4 pb-24 flex-grow">
              <h4 className="py-6 text-3xl font-bold text-black text-center">Professional</h4>
              <ul className="px-8 text-sm pricing-list font-source-sans-pro">
                <li>Tercen Cloud</li>
                <li>Full Tercen Feature Set (Including Projects, Teams, Public and Private sharing)</li>
                <li>1 TB of storage</li>
                <li>100 hours of CPU/month</li>
                <li>5 Hours Data Consultancy</li>
              </ul>
            </div>
            <div className="bg-gray-100 text-center py-6">
              <p className="text-5xl font-black text-black">
                50<span className="text-xl font-light font-source-sans-pro uppercase">€/month</span>
              </p>
              <p className="text-sm font-light">Ex. VAT</p>
            </div>
            <a
              href={data.site.siteMetadata.signUpLink}
              target="_blank"
              rel="noreferrer"
              className="flex font-source-sans-pro text-xl font-bold tracking-wide justify-center bg-tercen-blue transition duration-500 ease-in-out hover:bg-tercen-blue-hover text-white py-3 px-10">
              Sign Up
            </a>
          </div>
          <div className="flex-1 m-4 shadow-xl flex flex-col">
            <div className="bg-tercen-gray py-1 mb-8 "></div>
            <div className="p-4 pb-24 flex-grow">
              <h4 className="py-6 text-3xl font-bold text-black text-center">Enterprise</h4>
              <ul className="px-8 text-sm pricing-list font-source-sans-pro">
                <li>Installation of Tercen on your LAN or Private Cloud</li>
                <li>Full Tercen Feature Set (Including Projects, Teams, Public and Private sharing)</li>
                <li>Scalable storage</li>
                <li>Configurable CPU use</li>
                <li>Support desk</li>
                <li>Customised integrations with your proprietary code.</li>
              </ul>
            </div>
            <div className="bg-gray-100 text-center py-6">
              <p className="text-5xl font-black text-black">
                <span className="text-2xl font-light font-source-sans-pro">Please Contact</span>
              </p>
              <p className="text-sm font-light">&nbsp;</p>
            </div>
            <Link
              to={`/contact`}
              className="flex font-source-sans-pro text-xl font-bold tracking-wide justify-center bg-tercen-gray transition duration-500 ease-in-out hover:bg-tercen-blue-hover text-white py-3 px-10">
              Contact Us
            </Link>
          </div>
        </div>
      </section>
      <section className="w-full py-24">
        <div className="container flex flex-col lg:flex-row justify-content-center divide-y md:divide-y-0 md:divide-x md:divide-tercen-gray">
          <div className="flex-1 text-center py-4">
            <h1 className="text-black font-bold text-2xl">Need a customised solution?</h1>
            <p className="py-3 font-source-sans-pro">Let us optimise your workflow with a dedicated app.</p>
            <Link to={`/contact`} className="text-black hover:text-tercen-blue-hover font-bold text-lg">
              Chat with us
            </Link>
          </div>
          <div className="flex-1 text-center py-4">
            <h1 className="text-black font-bold text-2xl">Are you an academic institution?</h1>
            <p className="py-3 font-source-sans-pro">Take advantadge of our academic support program.</p>
            <Link to={`/contact`} className="text-black hover:text-tercen-blue-hover font-bold text-lg">
              Apply now
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

interface PageQueryData {
  site: {
    siteMetadata: {
      title: string
      signUpLink: string
    }
  }
  heroBackground: SharpImage
}

export const pageQuery = graphql`
  query PricingQuery {
    site {
      siteMetadata {
        title
        signUpLink
      }
    }
    heroBackground: file(relativePath: {eq: "Pricing_header.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 70, layout: FULL_WIDTH)
      }
    }
  }
`
export default Pricing
